<template>
	<form @submit.prevent="$emit('submit')" autocomplete="off">
		<div class="form-group">
			<label for="username">Avatar</label>
			<agent-avatar-selection :disabled="disabled" v-model="model.Avatar"></agent-avatar-selection>
		</div>
		<div class="form-group">
			<label for="username">Username</label>
			<div class="input-group">
				<div class="input-group-prepend">
					<div class="input-group-text">
						<i class="fa fa-address-card"></i>
					</div>
				</div>
				<input :disabled="disabled" autocomplete="off" id="username" v-model="model.Username" name="username"
							 placeholder="Username"
							 type="text" required="required"
							 class="form-control">
			</div>
		</div>
		<div class="form-group">
			<label for="email">Email</label>
			<div class="input-group">
				<div class="input-group-prepend">
					<div class="input-group-text">
						<i class="fa fa-envelope"></i>
					</div>
				</div>
				<input :disabled="disabled" autocomplete="off" id="email" v-model="model.Email" name="email" placeholder="Email"
							 type="email"
							 class="form-control">
			</div>
		</div>
		<div class="form-group">
			<label for="password">Password</label>
			<password-input :disabled="disabled" autocomplete="off" v-model="model.Password"></password-input>
			<a href="javascript:void(0)" class="btn btn-primary btn-sm text-small mt-2"
				 @click.prevent.stop="generatePassword" v-if="!disabled">Get a random password</a>
		</div>
		<div class="form-group" v-if="showAccess">
			<label for="access">Access</label>
			<div>
				<select :disabled="disabled" autocomplete="false" v-model="model.Access" id="access" name="access"
								class="form-control">
					<option value="ALLOW">Allow</option>
					<option value="DENY">Deny</option>
				</select>
			</div>
		</div>
		<div class="form-group" v-if="showRole">
			<label for="role">Role</label>
			<div>
				<select :disabled="disabled" autocomplete="false" v-model="model.Rolefk" id="role" name="role"
								class="form-control">
					<option :value="null" v-if="model.Rolefk === null">Select a Role</option>
					<option :value="role.Id" v-for="role in roles" :key="role.Code" v-text="role.Name"></option>
				</select>
			</div>

			<h4>Languages</h4>
			<language-selector v-model="model.Limitations.languages"/>
			<div class="p-2 bg-light">Please leave blank to disable language checks</div>
		</div>

		<div class="form-group" v-if="!disabled">
			<button name="submit" type="submit" class="btn btn-primary">Save</button>
		</div>
	</form>
</template>

<script>
import generatePassword from "omgopass";
import PasswordInput from "./password-input";
import AgentAvatarSelection from "./agent-avatar-selection";
import api from '@/api'
import LanguageSelector from "../localizations/LanguageSelector";

export default {
	components: {LanguageSelector, AgentAvatarSelection, PasswordInput},
	props: {
		showRole: {
			type: Boolean,
			default: false
		},
		showAccess: {
			type: Boolean,
			default: false
		},
		value: {
			default() {
				return {}
			},
			type: Object,
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			roles: []
		}
	},
	methods: {
		generatePassword() {
			this.model.Password = generatePassword({})
			console.log('generatePassword', this.model.Password)
		}
	},
	async mounted() {
		if (this.showRole) {
			this.roles = await api.Roles.getList()
		}
		if (this.model.Limitations === null) {
			this.$nextTick(() => this.model.Limitations = {})
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
