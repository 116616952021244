<template>
	<div>
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'users-index'}">Users</router-link>
				</li>
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'users-new'}">New user</router-link>
				</li>
			</ol>
		</nav>

		<h3>New user</h3>

		<agent-profile-form v-if="model" v-model="model" :show-access="true" :show-role="true"
												@submit="save"></agent-profile-form>
	</div>
</template>

<script>
import api from '@/api'
import AgentProfileForm from "../../components/agents/agent-profile-form";

export default {
	components: {AgentProfileForm},
	data() {
		return {
			loading: false,
			model: {
				Username: null,
				Email: null,
				Password: null,
				Avatar: null,
				Access: 'ALLOW',
				Rolefk: null,
				Limitations: {
					languages: []
				}
			}
		}
	},
	methods: {
		async save() {
			this.loading = true
			let agent = await api.Agents.saveNew(this.model).finally(() => this.loading = false)
			this.$router.push({name: 'users-edit-user_id', params: {user_id: agent.Id}})
		}
	}
}
</script>
