<template>
	<img class="img-fluid avatar" :src="avatar" :style="{width}">
</template>

<script>
	export default {
		props: {
			width: {
				type: String,
				default: '32px'
			},
			avatar: {
				type: String,
				default: null
			}
		}
	}
</script>
