<template>
	<div class="input-group">
		<div class="input-group-prepend">
			<div class="input-group-text">
				<i class="fa fa-asterisk"></i>
			</div>
		</div>
		<input :disabled="disabled" id="password" v-model="model" name="password" placeholder="Password" :type="readable ? 'text' : 'password'"
			   class="form-control" autocomplete="new-password">
		<div class="input-group-append">
			<div class="input-group-text cursor-pointer" @click.prevent="readable = !readable">
				<i class="fa" :class="{'fa-eye': !readable, 'fa-eye-slash': readable}"></i>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: String,
				default: ''
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				readable: false
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			}
		}
	}
</script>
