<template>
	<select multiple class="form-control" v-model="model">
		<option v-for="language in languages" v-text="language.Name" :value="language.Code"></option>
	</select>
</template>
<script>
import api from '@/api'

export default {
	name: 'language-selector',
	props: {
		value: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		languages: []
	}),
	async mounted() {
		this.languages = await api.Localizations.Languages.getList()
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
