<template>
	<div>
		<agent-profile-picture class="cursor-pointer avatar" @click.native="setAvatar(avatar)" :avatar="avatar"
							   v-for="avatar in avatars" :class="{selected: avatar === value}" :key="avatar" v-if="!disabled || avatar === value"></agent-profile-picture>
	</div>
</template>

<script>
	import AgentProfilePicture from "./agent-profile-picture";

	export default {
		components: {AgentProfilePicture},
		props: {
			value: {
				type: String,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		mounted() {
			if (this.value == null) {
				this.setAvatar(this.avatars[0]);
			}
		},
		methods: {
			setAvatar(avatar) {
				this.$emit('input', avatar)
			}
		},
		computed: {
			avatars() {
				let _a = []
				for (let i = 1; i <= 16; i++) {
					_a.push(`avatars/avatar-${i}.svg`)
				}
				return _a
			}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/style/_palette";

	.avatar {
		padding: 1px;
		border-radius: 50%;

		&:hover {
			background: transparentize($color-primary, 0.4);
		}

		&.selected {
			background: $color-dark-grey;
		}
	}
</style>
